import { formatDate } from './formatDate';
import { formatDocument } from './formatDocument';
function doesUserDocumentContainCompanyDocument(userDoc, companies) {
  if (!userDoc) {
    return false;
  }
  return [companies].some((item) => {
    const cleanedCompanyDocument = item?.document.replace(/[^\d]/g, '');
    const cleanedUserDocument = userDoc?.replace(/[^\d*]/g, '');

    let j = 0;
    for (let i = 0; i < cleanedUserDocument.length; i++) {
      if (
        cleanedUserDocument[i] === '*' ||
        cleanedUserDocument[i] === cleanedCompanyDocument[j]
      ) {
        j++;
      }
      if (j === cleanedCompanyDocument.length) {
        return true;
      }
    }
    return false;
  });
}
export const formatExtractDataGeneral = async (array, company) => {
  let orders = array.balances
    .sort((a, b) => b.id - a.id)
    .map((item) => {
      return {
        ...item,
        company: item?.company_id,
        order: array.orders.find((order) => item.order_key === order.key),
      };
    })
    .map((item) => {
      return {
        ...item,
        company: item?.company_id,

        order: {
          ...item.order,
          movements: item?.order?.movements?.filter(
            (mov) => item.movement_key === mov.key,
          ),
        },
      };
    });

  let list = orders
    ?.flatMap((item) => {
      if (item?.order?.movements) {
        return item?.order?.movements.map((list) => ({
          ...list,
          id: item?.id,
          additional_data_order: item?.order?.additional_data ?? ' ',
          additional_data: list?.additional_data ?? ' ',
          orderKey: item.order?.key ?? null,
          movementKey: list?.key ?? null,
          document: list?.document ? formatDocument(list?.document) : '---',
          service: formatMethods(list.method),
          method: list?.method,
          company_id: item.company_id,
          date: formatDate(list?.start_date, 'dd/MM/yyyy - HH:mm:ss'),
          type: item.type,
          name_cont: list.name,
          after: item.after,
          before: item.before,
          fee_amount: item.fee_amount ?? 0,
          OFX: {
            DTSTART: list?.start_date ?? list?.created_at,
            DTEND: list?.finish_date ?? list?.created_at,
            DTPOSTED: list?.created_at ?? item?.created_at,
            TRNTYPE: formatType(item?.type).OFX,
            TRNAMT: list?.amount,
            FITID: item?.id,
            MEMO: list?.additional_data
              ? list?.additional_data
              : item?.description ?? false,
            BALAMT: item.after,
            DTASOF: item?.created_at,
            BANKID: list?.bank_code === 'OX' ? 31680151 : list?.bank_code,
            ACCTID: list?.account ? list?.account : list?.document,
          },
          amount:
            item?.type === 15
              ? item?.fee_amount
              : item.type === 7
              ? item?.amount
              : list?.amount,
          name:
            item.type === 1
              ? company.find((company) => company.value === item.company_id)
                  ?.title
              : company.find((valueCompany) =>
                  doesUserDocumentContainCompanyDocument(
                    list.document,
                    valueCompany,
                  ),
                )?.title || list.name,
        }));
      } else {
        return {
          ...item,
          id: item?.id,
          after: item.after,
          before: item.before,
          service: formatMethods(item?.method),
          date: formatDate(item.created_at, 'dd/MM/yyyy - HH:mm:ss'),
          document:
            item?.document.replace(/[^\d]/g, '') >= 11
              ? formatDocument(item?.document)
              : item?.company_id,
          company_id: item?.company_id,
          type: item.type,
          OFX: {
            DTSTART: item?.start_date ?? item?.created_at,
            DTEND: item?.finish_date ?? item?.created_at,
            DTPOSTED: item?.created_at,
            TRNTYPE: formatType(item?.type).OFX,
            TRNAMT: item?.amount,
            FITID: item?.id,
            MEMO: item?.additional_data
              ? item?.additional_data
              : item?.description ?? false,
            BALAMT: item.after,
            DTASOF: item?.created_at,
            BANKID: item?.bank_code === 'OX' ? 31680151 : item?.bank_code,
            ACCTID: item?.account ? item?.account : item?.document,
          },
        };
      }
    })
    .reverse()
    .reduce(
      (sum, item) => {
        return {
          data: [
            {
              ...item,
              title: formatType(item?.type),
              fantasy:
                item.type === 1 || item.type === 4 || item.type === 2
                  ? company.find((company) => company.value === item.company_id)
                      ?.title
                  : company.find((valueCompany) =>
                      doesUserDocumentContainCompanyDocument(
                        item.document,
                        valueCompany,
                      ),
                    )?.title || item.name,
              name:
                company.find((valueCompany) =>
                  doesUserDocumentContainCompanyDocument(
                    item.document,
                    valueCompany,
                  ),
                )?.title || item.name,
              fantasy_document: company.find(
                (company) => company.value === item.company_id,
              )?.document,
              movementType: formatMovementType(item?.type, item?.method),
              movementStatus: formatMovementStatus(item?.status),
            },
            ...sum.data,
          ],
          service: removeDuplicates(
            [...new Set([item.method, ...sum.service])].filter(Boolean),
          ),
          document: removeDuplicates(
            [...new Set([item.document, ...sum.document])].filter(Boolean),
          ),
          type: removeDuplicates(
            [...new Set([item.type, ...sum.type])].filter(Boolean),
          ),
          name: removeDuplicates(
            [...new Set([item.name, ...sum.name])].filter(Boolean),
          ),
        };
      },
      { data: [], service: [], document: [], type: [], name: [] },
    );

  return {
    data: list.data,
    service: list.service
      .map((item) => formatMethods(item))
      .map((item) => ({ label: item.value, value: item.key }))
      .filter(
        (obj, index, self) =>
          index === self.findIndex((t) => t.value === obj.value),
      ),
    type: list.type
      .map((item) => formatType(item))
      .map((item) => ({ label: item.description, value: item.idBalanceType })),

    document: list.document.map((item) => ({
      label: formatDocument(item),
      value: item,
    })),
    name: list.name.map((item) => ({
      label: item,
      value: item,
    })),
  };
};

export const formatMovementStatus = (movementStatus) => {
  if (['01', '03', '08', '09'].includes(movementStatus)) {
    return 'pending';
  }
  if (['02', '04'].includes(movementStatus)) {
    return 'processing';
  }
  if (movementStatus === '06') {
    return 'success';
  }
  if (movementStatus === '07') {
    return 'refund';
  }
  return null;
};

export const formatMovementType = (orderType, paymentMethod) => {
  if (['02', '05', '07'].includes(paymentMethod) || orderType === 4) {
    return {
      value: 'deposit',
      label: 'Depósito',
    };
  }
  if (paymentMethod === '01') {
    return {
      value: 'ted',
      label: 'TED',
    };
  }
  if (paymentMethod === '03') {
    return {
      value: 'pix',
      label: 'PIX',
    };
  }
  if (paymentMethod === '03') {
    return {
      value: 'internal',
      label: 'Transferência interna',
    };
  }
  if (['04', '08']) {
    return {
      value: 'payment',
      label: 'Pagamento',
    };
  }
  return null;
};

export const formatSingleMovement = async (movement, order, companies) => {
  return {
    additional_data: movement?.additional_data,
    orderKey: order?.key ?? null,
    movementKey: movement?.key ?? null,
    document: movement?.document.replace(/[^\d]/g, ''),
    service: formatMethods(movement.method),
    method: movement?.method,
    company_id: order.company_id,
    date: formatDate(movement?.start_date, 'dd/MM/yyyy - HH:mm:ss'),
    type: order.type,
    name: movement?.name,
    title: formatType(order?.type),
    bank_autentication_code: movement?.bank_autentication_code,
    pix_end_to_end_id: movement?.pix_end_to_end_id,
    bank_slip: movement?.bank_slip,
    amount: movement?.amount,
    fantasy:
      order?.type === 1 || order?.type === 4 || order?.type === 2
        ? companies.find((company) => company.value === order?.company_id)
            ?.title
        : movement?.name,
    fantasy_document: companies.find(
      (company) => company.value === order?.company_id,
    )?.document,
    bank_code: movement?.bank_code,
    description: order?.description,
    movementType: formatMovementType(order?.type, movement?.method),
    movementStatus: formatMovementStatus(movement?.status),
  };
};

export function removeDuplicates(array) {
  return array.filter((item, index) => array.indexOf(item) === index);
}

export const formatType = (type) => {
  const types = [
    {
      idBalanceType: 0,
      name: 'not_found',
      description: 'Indefinido',
      operation: '---',
      OFX: 'OTHER',
      color: 'var(--red-500)',
      background: 'var(--gray-50)',
    },
    {
      idBalanceType: 1,
      name: 'adding_balance_account',
      description: 'Depósito',
      operation: 'Credit',
      OFX: 'CREDIT',
      color: 'var(--green)',
      background: 'var(--gray-50)',
    },
    {
      idBalanceType: 2,
      name: 'outbound_transfer',
      description: 'Pagamento',
      operation: 'Debit',
      OFX: 'DEBIT',
      color: 'var(--gray-600)',
      background: 'var(--white)',
    },
    {
      idBalanceType: 3,
      name: 'outbound_payment',
      description: 'Pagamento de Titulos',
      operation: 'Debit',
      OFX: 'DEBIT',
      color: 'var(--red)',
      background: 'var(--white)',
    },
    {
      idBalanceType: 4,
      name: 'refund_transfer',
      description: 'Estorno ',
      operation: 'Credit',
      OFX: 'CREDIT',
      color: 'var(--green)',
      background: 'var(--gray-50)',
    },
    {
      idBalanceType: 5,
      name: 'refund_payment',
      description: 'Devolução de Pagamento de Titulos',
      operation: 'Credit',
      OFX: 'CREDIT',
      color: 'var(--green)',
      background: 'var(--gray-50)',
    },
    {
      idBalanceType: 6,
      name: 'outbound_validate',
      description: 'Validação de Dados',
      operation: 'Debit',
      OFX: 'DEBIT',
      color: 'var(--red)',
      background: 'var(--white)',
    },
    {
      idBalanceType: 7,
      name: 'outbound_monthly_fee',
      description: 'Desconto de Antecipação',
      operation: 'Debit',
      OFX: 'DEBIT',
      color: 'var(--red)',
      background: 'var(--white)',
    },
    {
      idBalanceType: 8,
      name: 'discount_monthly_fee',
      description: 'Desconto Mensalidade',
      operation: 'Credit',
      OFX: 'CREDIT',
      color: 'var(--green)',
      background: 'var(--gray-50)',
    },
    {
      idBalanceType: 9,
      name: 'antecipation',
      description: 'Antecipação',
      operation: 'Credit',
      OFX: 'CREDIT',
      color: 'var(--green)',
      background: 'var(--gray-50)',
    },
    {
      idBalanceType: 99,
      name: 'discount_monthly_fee',
      description: 'Aporte Credito',
      operation: 'Debit',
      OFX: 'DEBIT',
      color: 'var(--red)',
      background: 'var(--gray-50)',
    },
    {
      idBalanceType: 11,
      name: 'unlocked_funds',
      description: 'Liberação de fundos restringidos',
      operation: 'Credit',
      OFX: 'CREDIT',
      color: 'var(--green)',
      background: 'var(--gray-50)',
    },
    {
      idBalanceType: 12,
      name: 'debt_payment',
      description: 'Pagamento de débito',
      operation: 'Debit',
      OFX: 'DEBIT',
      color: 'var(--red)',
      background: 'var(--gray-50)',
    },
    {
      idBalanceType: 13,
      name: 'pix_refund',
      description: 'Estorno de Pix',
      operation: 'Debit',
      OFX: 'DEBIT',
      color: 'var(--red)',
      background: 'var(--gray-50)',
    },
    {
      idBalanceType: 14,
      name: 'balance_sync',
      description: 'Sincronização de saldo com celcoin',
      operation: 'Both',
      OFX: 'OTHER',
      color: 'var(--gray-600)',
      background: 'var(--gray-50)',
    },
    {
      idBalanceType: 15,
      name: 'fee_collection',
      description: 'Coleta de Taxa',
      operation: 'Credit',
      OFX: 'CREDIT',
      color: 'var(--gray--600)',
      background: 'var(--gray-50)',
    },
    {
      idBalanceType: 16,
      name: 'consignation_bill_refund',
      description: 'Estorno de Cobrança de Consignação',
      operation: 'Credit',
      OFX: 'CREDIT',
      color: 'var(--green)',
      background: 'var(--gray-50)',
    },
    {
      idBalanceType: 17,
      name: 'consignation_collection',
      description: 'Coleta de Consignação',
      operation: 'Credit',
      OFX: 'CREDIT',
      color: 'var(--green)',
      background: 'var(--gray-50)',
    },
    {
      idBalanceType: 18,
      name: 'consignation_refund',
      description: 'Estorno de Consignação',
      operation: 'Credit',
      OFX: 'CREDIT',
      color: 'var(--gray-600)',
      background: 'var(--gray-50)',
    },
    {
      idBalanceType: 19,
      name: 'card',
      description: 'Uso do cartão',
      operation: 'Debit',
      OFX: 'DEBIT',
      color: 'var(--gray-600)',
      background: 'var(--gray-50)',
    },
    {
      idBalanceType: 20,
      name: 'card_refund',
      description: 'Devolução de uso de cartão',
      operation: 'Credit',
      OFX: 'CREDIT',
      color: 'var(--gray-600)',
      background: 'var(--gray-50)',
    },
    {
      idBalanceType: 21,
      name: 'linked_account_bill',
      description: 'Taxa de conta ativa',
      operation: 'Debit',
      OFX: 'DEBIT',
      color: 'var(--gray-600)',
      background: 'var(--gray-50)',
    },
    {
      idBalanceType: 22,
      name: 'linked_account_bill_collection',
      description: 'Coleta de taxa de conta ativa',
      operation: 'Credit',
      OFX: 'CREDIT',
      color: 'var(--gray-600)',
      background: 'var(--gray-50)',
    },
  ];

  return types.find((item) => item.idBalanceType === type) || types[0];
};

function formatMethods(type) {
  switch (type) {
    case '01':
      return { key: 1, value: 'TED', label: 'TED OUT', type: type };
    case '02':
      return { key: 2, value: 'PIX', label: 'PIX IN', type: type };
    case '03':
      return { key: 2, value: 'PIX', label: 'PIX OUT', type: type };
    case '04':
      return { key: 2, value: 'PIX', label: 'PIX KEY', type: type };
    case '05':
      return { key: 1, value: 'TED', label: 'TED IN', type: type };
    case '06':
      return {
        key: 3,
        value: 'P2P',
        label: 'INTERNAL OUT',
        type: type,
      };
    case '07':
      return { key: 3, value: 'P2P', label: 'INTERNAL IN', type: type };
    case '08':
      return { key: 4, value: 'BOLETO', label: 'BANKSLIP', type: type };
    case '10':
      return { key: 10, value: 'APORTE', label: 'APORTE', type: type };
    case 'BankSlip':
      return { key: 4, value: 'BOLETO', label: 'BANKSLIP', type: type };
    case 'BANKSLIP':
      return { key: 4, value: 'BOLETO', label: 'BANKSLIP', type: type };

    default:
      return { key: 99, value: '---' };
  }
}
