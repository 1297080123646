import styles from './styles.module.scss';
import { MdClose, MdEdit } from 'react-icons/md';
import { RxReset } from 'react-icons/rx';
import FormGroup from '../FormGroup';
import { useCustomers } from '../../../hooks/useCustomers';
import { Loading } from '../../UI/Loading';
import { useState, memo, createRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectPicker, RadioGroup, Radio, CheckPicker } from 'rsuite';

import { ValidateFormCustomer } from '../../../Helpers/validateFormCustomers';
import { ValidateFormCustomerNoDocument } from '../../../Helpers/validateFormCustomersNoDocument';
import { useUser } from '../../../hooks/useUser';

const FormCostumer = ({
  first_name,
  last_name,
  document,
  telephone,
  email,
  date_added,
  customer_id,
  is_active,
  alter,
  company,
  profile,
  capabilities,
  additional_company_ids,
}) => {
  const {
    setIsNewCustomer,
    fetchAddCustomers,
    fetchEditProfile,
    fetchEditCapabilities,
    fetchEditCustomers,
    loading,
    allProfiles,
    allCapabilities,
  } = useCustomers();
  const { user } = useUser();

  const [edit, setEdit] = useState(alter ?? true);
  const [editingProfile, setEditingProfile] = useState(
    ![50].every((item) => user?.permission.includes(item)) ? true : profile,
  );

  const [editingCapabilities, setEditingCapabilities] = useState(
    capabilities
      ? capabilities
      : allProfiles
      ? allProfiles.find((obj) => obj.id === profile)?.capabilities
      : [],
  );

  const [permissionEditor, setPermissionEditor] = useState('profile');

  const dataCustomers = !edit
    ? false
    : {
        first_name,
        last_name,
        document: document.replace(/[^\d]+/g, ''),
        telephone,
        email,
        date_added: date_added.slice(0, 10),
        customer_id,
        is_active,
      };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: dataCustomers ?? false,
    resolver: yupResolver(
      email ? ValidateFormCustomerNoDocument : ValidateFormCustomer,
    ),
  });

  const canEditUserPermissions = () => {
    if (
      !customer_id ||
      !user?.permission.includes(50) ||
      (user?.company_id !== company.company_id &&
        !additional_company_ids.includes(user?.company_id) &&
        !user?.additional_company_ids.includes(company.company_id) &&
        !user?.additional_company_ids.some((companyId) =>
          additional_company_ids.includes(companyId),
        ) &&
        !user?.permission.includes(0))
    ) {
      return true;
    }
    return true;
  };

  async function fetchCustomers(data) {
    if (!customer_id) {
      const userCreate = await fetchAddCustomers({
        ...data,
        ...customer_id,
        ...is_active,
        company: company.company_id,
        edit: false,
      });

      if (
        [50].every((item) => user?.permission.includes(item)) &&
        canEditUserPermissions()
      ) {
        let initialCapabilities =
          userCreate?.capabilities ||
          allProfiles.find((obj) => obj.id === userCreate?.profile)
            ?.capabilities ||
          [];
        if (
          permissionEditor === 'profile' &&
          editingProfile !== userCreate?.profile
        ) {
          await fetchEditProfile(
            userCreate?.customer_id,
            editingProfile,
            userCreate.company_id,
          );
          setEdit(false);
          setIsNewCustomer(false);
        } else if (
          permissionEditor === 'capabilities' &&
          editingCapabilities !== initialCapabilities
        ) {
          let removedCapabilities =
            initialCapabilities &&
            initialCapabilities.filter(
              (item) => !editingCapabilities.includes(item),
            );
          let addedCapabilities =
            editingCapabilities &&
            editingCapabilities.filter(
              (item) => !initialCapabilities.includes(item),
            );

          if (removedCapabilities.length)
            await fetchEditCapabilities(
              userCreate?.customer_id,
              removedCapabilities,
              userCreate?.company?.company_id ?? userCreate?.company_id,
              'Remove',
            );
          if (addedCapabilities.length)
            await fetchEditCapabilities(
              userCreate?.customer_id,
              addedCapabilities,
              userCreate?.company?.company_id ?? userCreate?.company_id,
              'Add',
            );
          setEdit(false);
          setIsNewCustomer(false);
        }
      }
    } else {
      await fetchEditCustomers({
        ...data,
        ...{ is_active: true },
        company: company.company_id,
        edit: true,
      });

      if (
        [50].every((item) => user?.permission.includes(item)) &&
        canEditUserPermissions()
      ) {
        let initialCapabilities =
          capabilities ||
          allProfiles.find((obj) => obj.id === profile)?.capabilities ||
          [];

        if (permissionEditor === 'profile' && editingProfile !== profile) {
          await fetchEditProfile(
            customer_id,
            editingProfile,
            company.company_id,
          );
          setEdit(false);
          setIsNewCustomer(false);
        } else if (
          permissionEditor === 'capabilities' &&
          editingCapabilities !== initialCapabilities
        ) {
          let removedCapabilities = initialCapabilities.filter(
            (item) => !editingCapabilities.includes(item),
          );
          let addedCapabilities = editingCapabilities.filter(
            (item) => !initialCapabilities.includes(item),
          );

          if (removedCapabilities?.length)
            await fetchEditCapabilities(
              customer_id,
              removedCapabilities,
              company.company_id,
              'Remove',
            );
          if (addedCapabilities?.length)
            await fetchEditCapabilities(
              customer_id,
              addedCapabilities,
              company.company_id,
              'Add',
            );
          setEdit(false);
          setIsNewCustomer(false);
        }
      }
    }
  }
  function handleActive(event) {
    event.preventDefault();
    setIsNewCustomer(false);
    setEdit(false);
  }

  const getCantEditCapabilities = () =>
    allCapabilities.filter((item) => !item.available).map((item) => item.id);

  const getCantEditProfiles = () => {
    let initialCapabilities =
      capabilities ||
      allProfiles.find((obj) => obj.id === profile)?.capabilities ||
      [];

    return (
      allProfiles &&
      allProfiles
        .filter(
          (item) =>
            !item.available ||
            (item.id !== 0 && initialCapabilities.some(
              (capability) =>
                !item.capabilities.includes(capability) &&
                getCantEditCapabilities().includes(capability),
            )),
        )
        .map((item) => item.id)
    );
  };

  const modalRef = createRef();

  return (
    <div className={styles.container} ref={modalRef}>
      <form onSubmit={handleSubmit(fetchCustomers)} className={styles.form}>
        <div className={styles.close}>
          <MdClose onClick={handleActive} size={32} />
          {alter && [7].every((item) => user?.permission.includes(item)) && (
            <MdEdit
              onClick={() => {
                setEdit(!edit);
              }}
              size={32}
            />
          )}
        </div>

        {canEditUserPermissions() &&
          [50].every((item) => user?.permission.includes(item)) && (
            <>
              <label className={styles.selectPickerLabel}>Permissões</label>
              <RadioGroup
                inline
                className={styles.radioGroup}
                disabled={edit}
                value={permissionEditor}
                onChange={setPermissionEditor}
              >
                <Radio value={'profile'} disabled={edit}>
                  Editar perfil
                </Radio>
                <Radio value={'capabilities'} disabled={edit}>
                  Customizar permissões
                </Radio>
              </RadioGroup>

              {permissionEditor === 'profile' ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '2rem',
                  }}
                >
                  <SelectPicker
                    data={allProfiles
                      .filter((item) => item.available || profile === item.id)
                      .map((p) => ({
                        value: p.id,
                        label: p.name,
                      }))}
                    menuClassName={styles.selectPickerMenu}
                    className={styles.selectPicker}
                    value={editingProfile}
                    onChange={setEditingProfile}
                    disabled={edit}
                    cleanable={false}
                    disabledItemValues={getCantEditProfiles()}
                    container={() => modalRef.current}
                  />
                  {editingProfile !== profile ? (
                    <RxReset
                      className={styles.resetButton}
                      size={34}
                      onClick={() => setEditingProfile(profile)}
                    />
                  ) : null}
                </div>
              ) : (
                <div>
                  <CheckPicker
                    className={styles.checkPicker}
                    placeholder="Seleção de permissões"
                    data={allCapabilities
                      .filter((item) => {
                        let initialCapabilities =
                          capabilities ||
                          allProfiles.find((obj) => obj.id === profile)
                            ?.capabilities ||
                          [];
                        return (
                          item.available ||
                          initialCapabilities.includes(item.id)
                        );
                      })
                      .map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                    menuClassName={styles.checkPickerMenu}
                    value={editingCapabilities}
                    onChange={setEditingCapabilities}
                    disabled={edit}
                    cleanable={false}
                    disabledItemValues={getCantEditCapabilities()}
                    container={() => modalRef.current}
                  />
                  {editingProfile !== profile ? (
                    <RxReset
                      className={styles.resetButton}
                      size={34}
                      onClick={() => setEditingProfile(profile)}
                    />
                  ) : null}
                </div>
              )}
            </>
          )}
        <FormGroup
          name="first_name"
          label="Nome"
          type="text"
          data={{ value: first_name }}
          disabled={edit}
          register={register}
          errors={errors}
        />
        <FormGroup
          name="last_name"
          label="Sobrenome"
          type="text"
          data={{ value: last_name }}
          disabled={edit}
          register={register}
          errors={errors}
        />
        <FormGroup
          name="document"
          label="CPF"
          type="text"
          data={{ value: document }}
          disabled={email ? true : edit}
          register={register}
          errors={errors}
          mask={'999.999.999-99'}
          setValue={setValue}
        />
        <FormGroup
          name="telephone"
          label="Telefone"
          type="text"
          data={{ value: telephone }}
          disabled={edit}
          register={register}
          errors={errors}
          mask={'(99) 99999-9999'}
          setValue={setValue}
        />
        <FormGroup
          name="email"
          label="E-mail"
          type="email"
          data={{ value: email }}
          disabled={edit}
          register={register}
          errors={errors}
        />
        {!loading?.add ? (
          <button
            style={edit ? { display: 'none' } : { display: 'inline' }}
            className={styles.button}
            type="submit"
            disabled={!editingProfile && !editingCapabilities}
          >
            {!document ? 'Cadastrar' : 'Salvar'}
          </button>
        ) : (
          <div
            style={{
              marginTop: '1rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Loading />
          </div>
        )}
      </form>
    </div>
  );
};

export default memo(FormCostumer);
