import { formatAccount } from '../../../../utils/formatAccount';

export const formatExcelData = (list) => {
  return list?.map((item) => ({
    Data: item?.date,
    'Saldo Anterior': item?.before,
    valor: item?.amount,
    'Saldo Posterior': item?.after,
    'Saldo Taxa': item?.fee_amount ?? 0,
    Serviço: item?.service?.label,
    Tipo: item?.title?.description,
    Favorecido: item?.name ? item?.name : ' ',
    Documento: item?.document,
    Email: item?.email,
    'Descrição do Pagamento': item?.additional_data
      ? item?.additional_data
      : ' ',
    'Descrição do Lote': item?.additional_data_order
      ? item?.additional_data_order
      : ' ',
    'Código do Banco': item?.bank_code,
    Agencia: item?.branch,
    'Digito da Agência': item?.branch_digit,
    'Numero da Conta': item?.account,
    'Digito da Conta': item?.account_digit,
    'Tipo de Conta': formatAccount(item?.account_type),
    Conta: item?.fantasy,
    'Chave Pix': item?.pix_key,
  }));
};
