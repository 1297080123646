export const splitTextIntoParagraphs = (text) =>
  text
    .toString()
    .split('\n')
    .filter((line) => (line?.trim() !== '' ? line.trim() : null));

export const splitSections = (paragraphs) => {
  let currentSection = [];
  let sections = [];

  paragraphs.forEach((para) => {
    if (
      para.trim() === 'BREAK_LINE_CONTRACT' ||
      para.trim() === '{BREAK_LINE_CONTRACT}'
    ) {
      if (currentSection.length > 0) {
        sections.push([...currentSection]);
      }
      currentSection = [];
    } else {
      currentSection.push(para.trim());
    }
  });

  if (currentSection.length > 0) {
    sections.push([...currentSection]);
  }

  return sections;
};

export const extractTableNumber = (marker) => {
  const regex = /\{TABLE_(\d+)\}/;
  const match = marker.match(regex);

  return match ? Number(match[1]) - 1 : 0;
};

// Função para converter a string de tabela em componentes React
export const parseTableString = (tableString) => {
  // Remover espaços em branco indesejados
  const cleanString = tableString.replace(/\s+/g, ' ').trim();

  // Separar os elementos da tabela
  const parts = cleanString
    .split(/(\{\/?\w+\})/g)
    .filter(Boolean)
    .filter((v) => v.trim().length);

  return groupTableElements(parts);
};

function groupTableElements(elements) {
  const stack = []; // Pilha para acompanhar a hierarquia de rows e cols
  let current = []; // Elemento atual que está sendo montado

  elements.forEach((element) => {
    switch (element) {
      case '{table_begin}':
        stack.push(current); // Iniciar a tabela e empilhar o array atual
        current = { type: 'table', content: [] };
        break;

      case '{row}':
        stack.push(current); // Iniciar uma nova row e empilhar o array atual
        current = { type: 'row', content: [] };
        break;

      case '{colHeader}':
        stack.push(current); // Iniciar uma nova colHeader e empilhar o array atual
        current = { type: 'colHeader', content: [] };
        break;

      case '{col}':
        stack.push(current); // Iniciar uma nova col e empilhar o array atual
        current = { type: 'col', content: [] };
        break;

      case '{/colHeader}':
      case '{/col}':
        // Fechar colHeader ou col, desempilhar o item anterior e adicionar o atual
        const completedCol = current;
        current = stack.pop();
        current.content.push(completedCol);
        break;

      case '{/row}':
        // Fechar row, desempilhar o item anterior e adicionar o atual
        const completedRow = current;
        current = stack.pop();
        current.content.push(completedRow);
        break;

      case '{table_end}':
        // Fechar tabela, desempilhar o item anterior e adicionar o atual
        const completedTable = current;
        current = stack.pop();
        current.push(completedTable);
        break;

      default:
        // Caso seja texto simples, adicionar ao conteúdo do elemento atual
        current.content.push(element);
        break;
    }
  });

  return current;
}

export const formatContract = (contract) => {
  let paragraphs = splitTextIntoParagraphs(contract).filter(
    (text) => text.trim() !== '.',
  );

  paragraphs = paragraphs.filter((text) => text[0] !== '§');

  const sections = splitSections(paragraphs);

  return sections.length ? sections : [];
};
