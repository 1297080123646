import * as yup from 'yup';

export const ValidateFormCustomerNoDocument = yup.object().shape({
  first_name: yup
    .string()
    .typeError('O campo deve receber um nome.')
    .min(0, 'O numero deve ser maior que 0.')
    .required('Nome é Obrigatório'),
  last_name: yup
    .string()
    .typeError('O campo deve receber um sobrenome.')
    .required('Sobrenome é obrigatório'),
  telephone: yup
    .string()
    .typeError('O campo deve receber um telefone.')
    .required('Telefone é obrigatório')
    .max(15, 'O numero de telefone inválido'),
  email: yup
    .string()
    .typeError('O campo deve receber um email.')
    .required('Email é obrigatório')
    .email('E-mail deve ser valido'),
});
