import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as XLSX from 'xlsx';
import { useFormData } from '../../../../hooks/useFormData';
import styles from '../../styles/styles.module.scss';
import FormGroup from './FormGroup';

import { yupResolver } from '@hookform/resolvers/yup';
import DropZone from 'react-drop-zone/dist/DropZone';
import {
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineMail,
} from 'react-icons/ai';
import { BiPhone } from 'react-icons/bi';
import { FaIdCard } from 'react-icons/fa';
import * as yup from 'yup';
const ValidateAddCompany = yup.object().shape({});
const ValidateAddPerson = yup.object().shape({
  phone_number: yup
    .string()
    .typeError('O campo deve receber um telefone.')
    .required('Telefone é obrigatório')
    .max(15, 'O numero de telefone inválido'),
  email: yup
    .string()
    .typeError('O campo deve receber um email.')
    .required('Email é obrigatório')
    .email('E-mail deve ser valido'),
  name: yup
    .string()
    .typeError('O campo deve receber um Nome.')
    .required('Nome é obrigatório'),
});
const ValidateOnboarding = yup.object().shape({});

export default function PaymentInfo({
  formStep,
  nextFormStep,
  prevFormStep,
  currentStep,
}) {
  const {
    setFormValuesPayment,
    setClearedFormPayment,
    typePayment,
    isOnboarding,
    formPayment,
  } = useFormData();
  const [file, setFile] = useState(false);
  const [clear] = useState(false);
  const [isContract, setIsContract] = useState(
    formPayment?.is_contract_required ?? true,
  );

  function excelToJson(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const dataJson = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        resolve(dataJson);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsArrayBuffer(file);
    });
  }
  function handlerIsContract(value) {
    setIsContract(value);
  }
  const ResolverCheck = isOnboarding
    ? ValidateOnboarding
    : typePayment === 'cpf'
    ? ValidateAddPerson
    : ValidateAddCompany;
  const handlerBack = () => {
    prevFormStep();
    setClearedFormPayment();
  };
  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
  } = useForm({ resolver: yupResolver(ResolverCheck) });

  const onSubmit = async (values) => {
    try {
      const file = values.file;
      const json = await excelToJson(file);
      //eslint-disable-next-line
      const amount = json.reduce((accumulator, linha) => {
        if (!isNaN(linha[10])) {
          return accumulator + linha[10];
        }
        return parseFloat(accumulator.toFixed(13));
      }, 0);
    } catch (error) {
      console.error('Erro ao converter o arquivo Excel:', error);
    }
    isOnboarding
      ? setFormValuesPayment(isOnboarding)
      : setFormValuesPayment(values);
    nextFormStep();
  };

  return (
    <div className={styles.showForm}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.formRow}>
          {isOnboarding && (
            <div className={styles.box_preview}>
              <div className={styles.box_right}>
                {isOnboarding?.name && (
                  <p className={styles.items}>
                    <h2> {isOnboarding.name}</h2>
                  </p>
                )}
                {isOnboarding?.cnpj && (
                  <p className={styles.items}>
                    <strong>
                      <FaIdCard size={'1.2rem'} color="var(--gray-400)" />
                      CNPJ:
                    </strong>
                    <span> {isOnboarding.cnpj}</span>
                  </p>
                )}

                <p className={styles.items}>
                  <strong>
                    <FaIdCard size={'1.2rem'} color="var(--gray-400)" />
                    CPF:
                  </strong>
                  <span> {isOnboarding.cpf}</span>
                </p>
                <p className={styles.items}>
                  <strong>
                    <BiPhone size={'1.2rem'} color="var(--gray-400)" />
                    Celular:
                  </strong>
                  <span>{isOnboarding.phone_number}</span>
                </p>
                <p className={styles.items}>
                  <strong>
                    <AiOutlineMail size={'1.2rem'} color="var(--gray-400)" />
                    Email:
                  </strong>
                  <span> {isOnboarding?.email}</span>
                </p>
              </div>
            </div>
          )}
          {typePayment === 1 && (
            <>
              <div className={styles.drop}>
                <DropZone
                  onDrop={(itemFile) => {
                    setFile(itemFile);
                    setValue('file', itemFile);
                  }}
                  register={register}
                >
                  {({ over }) => (
                    <div className="drop-zone">
                      {over
                        ? 'Solte o arquivo'
                        : file
                        ? file.name
                        : 'Arraste o arquivo'}
                    </div>
                  )}
                </DropZone>
              </div>

              <FormGroup
                name="description"
                label="Descrição:"
                type="text"
                placeholder="Descrição"
                data={{ ...register('description') }}
                disabled={false}
                register={register}
                errors={errors}
              />
              <div className={styles.label_radio}>Desconto Automático</div>
              <div className={styles.wrapper_radio}>
                <p>
                  <input
                    type="radio"
                    id="is_contract_required-true"
                    name="is_contract_required"
                    value={'true'}
                    checked={isContract === true}
                    onClick={() => handlerIsContract(true)}
                    {...register('is_contract_required', {
                      required: true,
                      message: 'O campo deve ser selecionado.',
                    })}
                  />
                  <label
                    htmlFor="is_contract_required"
                    for="is_contract_required-true"
                  >
                    sim
                  </label>
                </p>
                <p>
                  <input
                    type="radio"
                    id="is_contract_required-false"
                    name="is_contract_required"
                    value={'false'}
                    checked={isContract === false}
                    onClick={() => handlerIsContract(false)}
                    {...register('is_contract_required', {
                      required: true,
                      message: 'O campo deve ser selecionado.',
                    })}
                  />

                  <label
                    htmlFor="is_contract_required"
                    for="is_contract_required-false"
                  >
                    não
                  </label>
                </p>
              </div>
            </>
          )}
          {typePayment === 'cnpj' && (
            <>
              <FormGroup
                name="name"
                label="Nome:"
                type="text"
                placeholder="Nome completo"
                data={{ ...register('name') }}
                disabled={false}
                register={register}
                errors={errors}
              />
              <FormGroup
                name="cpf"
                label="CPF:"
                type="text"
                placeholder="999.999.999-99"
                data={{ ...register('cpf') }}
                disabled={false}
                register={register}
                errors={errors || { document: 'Documento inválido' }}
                mask={['999.999.999-99']}
                setValue={setValue}
                clean={clear}
              />

              <FormGroup
                name="email"
                label="email:"
                type="email"
                placeholder="exemple@mail.com"
                data={{ ...register('email') }}
                disabled={false}
                register={register}
                errors={errors}
              />
              <FormGroup
                name="phone_number"
                label="Celular:"
                type="tel"
                placeholder="(99) 99999-9999"
                data={{ ...register('phone_number') }}
                disabled={false}
                register={register}
                errors={errors}
                setValue={setValue}
                mask={'(99) 99999-9999'}
                clean={clear}
              />
            </>
          )}
        </div>
        <div className={styles.next_button}>
          {currentStep > 0 && (
            <button onClick={handlerBack} type="button">
              <AiOutlineArrowLeft color="var(--white)" size={20} />
              Voltar
            </button>
          )}
          <button type="submit">
            Proximo <AiOutlineArrowRight color="var(--white)" size={20} />
          </button>
        </div>
      </form>
    </div>
  );
}
