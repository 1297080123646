import React, { useEffect, useState } from 'react';
import { RiSearch2Line } from 'react-icons/ri';

import { InputSearch } from '../../components/UI/InputSearch';

import { Link } from 'react-router-dom';
import { useUser } from '../../hooks/useUser';
import { lower } from '../../utils/lower';
import styles from './styles.module.scss';

import { useQueryClient } from 'react-query';
import PaginationArrow from '../../components/Pagination/PaginationArrow';
import { LoadingBall } from '../../components/UI/LoadingBall';
import { usePagination } from '../../hooks/usePagination';
import { useConsignableCollaboratorQuery } from '../../hooks/useQuery';
import CardCollaborator from './Components/CardCollaborator';

import { CheckPicker, Loader } from 'rsuite';
import { ButtonFilterSelect } from './Components/ButtonFilterSelect';
import { ButtonMenuDownload } from './Components/ButtonMenuDownload';
import { HeaderCollaborator } from './Components/HeaderCollaborator';
import { statusCheck } from './data';

import { MdDeleteOutline } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { useCollaborator } from '../../hooks/useCollaborator';
import { useTags } from '../../hooks/useTags';
import DraggableList from './Components/DraggableList';
import { IconPlus } from './Components/Icon';
import TagInput from './Components/TagInput';
import { IconTags } from './Components/TagInput/icon';
export default function Collaborators() {
  const [search, setSearch] = useState(false);
  const [status, setStatus] = useState([]);
  const [tags, setTags] = useState([]);
  const history = useHistory();
  const prevPath = history.location.state?.from || '/';
  const [listSelect, setListSelect] = useState([]);
  const { user } = useUser();
  const [firstClick, setFirstClick] = useState(true);
  const { tagsRef, removeTagCompany, fetchTags } = useTags();
  const queryClient = useQueryClient();
  const [openInputTags, setOpenInputTags] = useState();
  const permissionTags = [40].every((item) => user?.permission.includes(item));
  const [, setPageRef] = useState(false);
  const permissionConfigTags = [63].every((item) =>
    user?.permission.includes(item),
  );

  const {
    selectCheckedTags,
    setSelectCheckedTags,
    statusCollaboratorSelect,
    setStatusCollaboratorSelect,
  } = useCollaborator();

  const { data, isFetchingNextPage, fetchNextPage, hasNextPage, isFetching } =
    useConsignableCollaboratorQuery();

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) fetchNextPage();
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  const [remove, setRemove] = useState([]);
  const selectTags = tagsRef.length
    ? tagsRef.map((item) => {
        return {
          value: item,
          label: item,
        };
      })
    : [];

  async function handleReload() {
    await queryClient.invalidateQueries(['ConsignableCollaborator']);
  }
  useEffect(() => {
    if (prevPath === '/menu/group-customer') {
      handleReload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);
  useEffect(() => {
    fetchTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const list = data
    ? data
        .filter((item) => {
          if (!search || /\d/.test(search)) return item;
          return lower(`${item['name']}`).includes(lower(search));
        })
        .filter((item) => {
          if (!search) return item;
          return (
            item['document']
              ?.replace(/\D/g, '')
              .includes(search?.replace(/\D/g, '')) ||
            [item['id'], item['order']].includes(search)
          );
        })
        .filter((item) => {
          if (statusCollaboratorSelect.length === 0) return item;

          return statusCollaboratorSelect.includes(item.status.id) && item;
        })
        .filter((item) => {
          if (status.length === 0) return item;

          return status.includes(item.status.id) && item;
        })
        .filter((item) => {
          if (selectCheckedTags.length === 0) return item;

          return item?.tags?.some((tag) => selectCheckedTags.includes(tag));
        })
    : [];

  const handleStatusChange = (value) => {
    setStatus(value);
  };
  const handleStatusChangeSelect = (value) => {
    setStatusCollaboratorSelect(value);
  };

  const handleSelectCheckedTagsChange = (value) => {
    setSelectCheckedTags(value.filter((item) => !remove.includes(item)));
  };
  const handleSelectCheckedTagsOpen = () => {
    setSelectCheckedTags([]);
  };
  const handleSelectCheckedStatusOpen = () => {
    setSelectCheckedTags([]);
  };

  const handleSelectDeleteAllTags = async () => {
    try {
      const result = await removeTagCompany(selectCheckedTags);
      if (result) {
        setRemove([...selectCheckedTags, ...remove]);
      }
    } catch (error) {
    } finally {
      await handleReload();
      setSelectCheckedTags([]);
      setFirstClick(true);
    }
  };
  const { currentPage, pages, currentItens, setCurrentPage } = usePagination(
    list,
    50,
    '/menu/contas/',
  );

  useEffect(() => {
    setPageRef({
      currentPage,
      pages: pages - 1,
    });
  }, [pages, currentPage]);

  useEffect(() => {
    if (search) {
      setCurrentPage(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  const toggleSelect = (itemId) => {
    if (listSelect.includes(itemId)) {
      setListSelect(listSelect.filter((id) => id !== itemId));
    } else {
      setListSelect([...listSelect, itemId]);
    }
  };

  const selectAll = () => {
    const ids = list.map((item) => item.id);

    if (firstClick) {
      setListSelect([]);
      setListSelect(ids);
    } else {
      setListSelect([]);
    }

    // Toggle the firstClick state
    setFirstClick(!firstClick);
  };
  const renderMenu = (menu) => {
    if (isFetching) {
      return (
        <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
          <Loader /> carregando...
        </p>
      );
    }
    return menu;
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper_balances}>
        <div className={styles.wrapper_header}>
          <h2 className={styles.title_style}>Cadastros</h2>
          <div className={styles.buttons}>
            <div className={styles.wrapper_indicators}>
              {[40].every((item) => user?.permission.includes(item)) && (
                <Link to="/menu/group-customer">
                  <IconPlus color="var(--white)" size="1.5rem" />
                  <span>Adicionar</span>
                </Link>
              )}
            </div>
          </div>
        </div>

        <div className={styles.search_container}>
          <InputSearch
            label="Pesquisar por nome ou CNPJ"
            type="text"
            setValue={setSearch}
            value={search}
            Icon={RiSearch2Line}
            width="100%"
          />

          <CheckPicker
            data={statusCheck}
            style={{ width: 200 }}
            placeholder="Status"
            onChange={handleStatusChangeSelect}
            defaultValue={statusCollaboratorSelect}
            cleanable={isFetching}
            onOpen={handleSelectCheckedStatusOpen}
            renderMenu={renderMenu}
          />

          <CheckPicker
            data={isFetching ? [] : selectTags}
            style={{ width: 250 }}
            placeholder="Tag"
            onChange={handleSelectCheckedTagsChange}
            defaultValue={selectCheckedTags}
            cleanable={isFetching}
            onOpen={handleSelectCheckedTagsOpen}
            renderMenu={renderMenu}
          />

          <ButtonMenuDownload list={list} />

          <>
            {selectCheckedTags.length > 0 ? (
              <>
                {permissionConfigTags && (
                  <button
                    className={styles.button_delete}
                    onClick={handleSelectDeleteAllTags}
                    disabled={isFetching}
                  >
                    {isFetching ? (
                      <>
                        <Loader /> ...carregando
                      </>
                    ) : (
                      <>
                        <MdDeleteOutline size={'1.5rem'} /> <p>Excluir Tags</p>
                      </>
                    )}
                  </button>
                )}
              </>
            ) : (
              <>
                {permissionTags && (
                  <button
                    className={styles.button_include}
                    onClick={() => setOpenInputTags(true)}
                    disabled={isFetching}
                  >
                    <IconTags /> <p>Adicionar Tags</p>
                  </button>
                )}
              </>
            )}
            {permissionConfigTags && (
              <DraggableList handleReload={handleReload} />
            )}
          </>
        </div>

        {(listSelect.length > 0 || openInputTags) && permissionTags && (
          <TagInput
            tags={tags}
            setTags={setTags}
            listSelect={listSelect}
            setListSelect={setListSelect}
            handleReload={handleReload}
            setOpenInputTags={setOpenInputTags}
            setFirstClick={setFirstClick}
            firstClick={firstClick}
          />
        )}

        <div className={styles.search_container_filter}>
          {(selectCheckedTags.length > 0 ||
            statusCollaboratorSelect.length > 0 ||
            search) && (
            <ButtonFilterSelect
              title={'Total'}
              account={list ? list?.length : 0}
              value={[]}
              isActive={[]}
              setIsActive={() => {}}
              setCurrentPage={() => {}}
            />
          )}
          {selectCheckedTags.length === 0 &&
            statusCollaboratorSelect.length === 0 &&
            !search && (
              <>
                <ButtonFilterSelect
                  title={'Todos'}
                  account={data ? data?.length : 0}
                  value={[]}
                  isActive={status}
                  setIsActive={handleStatusChange}
                  setCurrentPage={setCurrentPage}
                />

                <ButtonFilterSelect
                  title={'Ativos'}
                  account={
                    data
                      ? data.filter((item) => item.status?.id === 33)?.length ??
                        0
                      : 0
                  }
                  value={[33]}
                  isActive={status}
                  setIsActive={handleStatusChange}
                  setCurrentPage={setCurrentPage}
                />

                <ButtonFilterSelect
                  title={'Inativo'}
                  account={
                    data
                      ? data.filter((item) => item.status?.id === 9)?.length ??
                        0
                      : 0
                  }
                  value={[9]}
                  isActive={status}
                  setIsActive={handleStatusChange}
                  setCurrentPage={setCurrentPage}
                />
              </>
            )}
          <div className={styles.container_arrow_box}>
            <PaginationArrow
              pages={pages}
              page={currentPage}
              setPage={setCurrentPage}
            />
          </div>
        </div>

        <HeaderCollaborator selectAll={selectAll} firstClick={firstClick} />
        <div className={styles.cards}>
          {currentItens.length >= 1 ? (
            currentItens.map((customer) => (
              <CardCollaborator
                key={customer.id}
                {...customer}
                toggleSelect={toggleSelect}
                listSelect={listSelect}
                handleReload={handleReload}
                selectCheckedTags={selectCheckedTags}
              />
            ))
          ) : (
            <div className={styles.listLoader}>
              {isFetching && (
                <div style={{ width: '12rem' }}>
                  <LoadingBall />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
