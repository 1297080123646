import { isJwtExpired } from 'jwt-check-expiration';
import { useContext } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { validateCPF, validateEmail } from 'validations-br';
import {
  getToken,
  removeCookieCompanies,
  removeCookieCompany,
  removeCookieCompanyId,
  removeCookieUser,
  removeToken,
  setCookieCompanyId,
  setToken,
} from '../Helpers/cookies';
import { CustomersContext } from '../data/customersContext';
import { StoreContext } from '../data/storeContext';
import { apiGet, apiPost } from '../services/api';
import {
  fireStoreAdd,
  fireStoreGetModalFeedback,
} from '../utils/firestoreActions';
import { formatCompaniesData } from '../utils/formatCompaniesData';
import { formatCompaniesValues } from '../utils/formatCompaniesValues';

import { useFeedback } from './useFeedBack';

import { formatContract } from '../utils/formatContract';
import { formatCustomers } from '../utils/formatCustomers';
import { formatDataOnboarding } from '../utils/formatDataOnboarding';
import { formatUserData } from '../utils/formatUserData';
import { useLocalStorage } from './useLocalStorage';
import { useNotification } from './useNotifications';
import { usePayment } from './usePayment';
import { useTags } from './useTags';
export function useUser() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { clearStatePayment } = usePayment();
  const {
    user,
    setUser,
    login,
    setLogin,
    loading,
    setLoading,
    error,
    setError,
    setOrderKey,
    setOrderTransfer,
    setOrder,
    setListOrder,
    setMessage,
    message,
    //balance
    setBalance,
    balance,
    setAllBalance,
    allBalance,
    fetchGetBalance,
    //listConsignable
    listConsignable,
    //menu
    expend,
    setExpend,
    dropMenu,
    setDropMenu,
    companies,
    setCompanies,
    setStartExtract,
    setWidgetConsignable,
    setWidgetTransfer,
    setSearchDocument,
    setLimits,
    isLinkClicked,
    setIsLinkClicked,
    customerListData,
    setCustomerListData,
  } = useContext(StoreContext);

  const { fetchBanks } = usePayment();
  const { fetchTags } = useTags();
  const { addNotification } = useNotification();
  const { setCustomers } = useContext(CustomersContext);
  const { handleOpenModal } = useFeedback();
  const companyStorage = useLocalStorage(null);
  const { getValue, setValue, clearValue } = useLocalStorage('');
  async function autoLogin(pathname, state) {
    const containsProofMobile = pathname.includes(['/proof/mobile']);

    const token = getToken();
    const userCook = await getValue('user');
    function isTokenValid(token) {
      return !isJwtExpired(token);
    }

    if (containsProofMobile) return;

    if (isTokenValid(token) && userCook) {
      try {
        setLoading({ login: true });
        setUser(userCook);
        setLogin(true);
        await fetchBanks();
        await fetchGetAllBalances();
        if ([62].every((item) => userCook?.permission.includes(item))) {
          await fetchTags();
        }
        if ([6].every((item) => userCook?.permission.includes(item))) {
          await fetchCustomersListData();
        }
        if (pathname === '/') {
          if ([16].every((item) => userCook?.permission.includes(item))) {
            history.push('/menu/transfer');
          } else if (
            [16].every((item) => userCook?.permission.includes(item))
          ) {
            history.push('/menu/consignment');
          } else if (
            [44, 46].every((item) => userCook?.permission.includes(item))
          ) {
            history.push('/menu/contas');
          } else {
            history.push('/menu/balances');
          }
        } else {
          history.push({ pathname, state });
        }
      } catch (err) {
      } finally {
        setLoading(false);
      }
    } else {
      if (!containsProofMobile) userLogout();
    }
  }
  async function fetchCollaborator(id) {
    const promises = [apiGet(`/onboarding/${id}`)];

    try {
      const [isOnboardingResponse] = await Promise.all(promises);
      let isOnboarding = await isOnboardingResponse.data.value;

      if (!!isOnboarding.contract_type) {
        try {
          const contract = await apiGet(`/contract/${id}`);

          isOnboarding = {
            ...isOnboarding,
            contracts: !!contract ? formatContract(contract) : [],
          };
        } catch (e) {
          isOnboarding = {
            ...isOnboarding,
            contracts: [],
          };
          console.error(e);
        }
      }

      if (
        !!isOnboarding.document ||
        !!isOnboarding.cpf ||
        !!isOnboarding.cnpj
      ) {
        const document =
          isOnboarding.document || isOnboarding?.cpf || isOnboarding?.cnpj;
        const history = await apiGet(
          `/company/onboardings?document=${document}&skip=0&count=1000`,
        );

        isOnboarding = {
          ...isOnboarding,
          history_data: (await history.data) || [],
        };
      }

      const dataOnboarding = formatDataOnboarding([isOnboarding], []);

      return dataOnboarding[0];
    } catch (error) {
      const status = await error?.response?.status;

      if (status === 401) {
        userLogout();
        setError({ order: 'Você não está autenticado' });
      } else if (status === 500) {
        setError({ order: 'Erro no servidor' });
      }
    } finally {
      setLoading(false);
    }
  }
  async function fetchGetListConsignable({
    pageParamOnboarding = 0,
    pageParamLinked = 0,
  }) {
    const count = 500;
    const skipOnboarding = pageParamOnboarding;
    const skipLinked = pageParamLinked;

    const stringQueryOnboarding = `/Company/onboardings/?skip=${skipOnboarding}&count=${count}`;
    const stringQueryLinked = `/Company/link/customers/?skip=${skipLinked}&count=${count}`;

    try {
      const [isOnboardingResponse, isLinkedResponse] = await Promise.all([
        apiGet(stringQueryOnboarding),
        apiGet(stringQueryLinked),
      ]);

      const isOnboarding = await isOnboardingResponse.data;
      const isLinked = await isLinkedResponse.data;

      const unifiedData = formatDataOnboarding(isOnboarding, isLinked);

      return {
        data: unifiedData,
        nextPageOnboarding:
          isOnboarding.length === count ? skipOnboarding + count : undefined,
        nextPageLinked:
          isLinked.length === count ? skipLinked + count : undefined,
      };
    } catch (error) {
      const status = error?.response?.status;

      if (status === 401) {
        userLogout();
        setError({ order: 'Você não está autenticado' });
      } else if (status === 500) {
        setError({ order: 'Erro no servidor' });
      }
      throw error;
    }
  }
  async function fetchGetListLinked() {
    const promises = [apiGet('/Company/link/customers/?skip=0&count=4000')];
    try {
      const [isLinkedResponse] = await Promise.all(promises);

      const isLinked = await isLinkedResponse.data;

      const dataOnboarding = formatDataOnboarding([], isLinked);

      return dataOnboarding;
    } catch (error) {
      const status = await error?.response?.status;

      if (status === 401) {
        userLogout();
        setError({ order: 'Você não está autenticado' });
      } else if (status === 500) {
        setError({ order: 'Erro no servidor' });
      }
    } finally {
      setLoading(false);
    }
  }
  async function fetchGetAllBalances(value = '') {
    try {
      setAllBalance([]);
      setLoading({ allBalance: true, company: true });

      if ([1].every((item) => user?.permission.includes(item)) && !!value) {
        const { data: companies } = await apiGet(`/Company/find/${value}`);

        const allBalancesData = formatCompaniesData(companies);
        const companiesValues = formatCompaniesValues(allBalancesData);

        return {
          companies: companiesValues,
          allBalance: allBalancesData,
        };
      } else {
        const { data } = await apiGet('/customer/company');

        const allBalancesData = formatCompaniesData(data);
        const companiesValues = formatCompaniesValues(allBalancesData);

        return {
          companies: companiesValues,
          allBalance: allBalancesData,
        };
      }
    } catch (error) {
      const status = await error?.response?.status;
      if (status === 401) {
        userLogout();
        setError({ order: 'Você não está autenticado' });
      } else if (status === 500) {
        setError({ order: 'Erro no servidor' });
      } else if (status === 400) {
        setError({ order: 'Documento inválido, revise os campos' });
      } else if (status === 409) {
        setError({
          order: 'Documento apresenta erros de formatação, revise os campos',
        });
      }
    } finally {
      setLoading(false);
    }
  }

  async function userLogin(email, password, isToken) {
    try {
      isToken ? setLoading({ login: true }) : setLoading(true);
      setError(false);

      const validateDocument = validateCPF(email)
        ? { type: 'document', value: email.replace(/\D/g, '') }
        : validateEmail(email)
        ? { type: 'email', value: email }
        : { type: 'email', value: email };

      const { data } = await apiPost(`/login/${validateDocument.type}`, {
        [validateDocument.type]: validateDocument.value,
        password,
        token: isToken,
      });

      const { user, access_token: token } = data;
      const { company_id } = user;

      setToken(token);
      setCookieCompanyId(company_id);

      const [dataCompany, dataCustomer] = await Promise.all([
        apiGet('/company').then((res) => res.data),
        apiGet('/customer').then((res) => res.data),
      ]);

      const { formattedUser, cookieData } = formatUserData(
        user,
        dataCompany,
        dataCustomer,
        isToken,
        token,
      );
      await setValue('user', cookieData);
      setUser(formattedUser);

      setLogin(true);

      if ([16].every((item) => formattedUser?.permission.includes(item))) {
        history.push('/menu/transfer');
      } else if (
        [16].every((item) => formattedUser?.permission.includes(item))
      ) {
        history.push('/menu/consignment');
      } else if (
        [44, 46].every((item) => formattedUser?.permission.includes(item))
      ) {
        history.push('/menu/contas');
      } else {
        history.push('/menu/balances');
      }
      const userModal = await fireStoreGetModalFeedback(company_id);
      await fetchBanks();
      if ([62].every((item) => formattedUser?.permission.includes(item))) {
        await fetchTags();
      }
      if ([6].every((item) => formattedUser?.permission.includes(item))) {
        await fetchCustomersListData();
      }

      handleOpenModal(userModal);

      await Promise.all([
        fetchGetAllBalances(),
        fireStoreAdd(
          { action: 'LOGIN_PLATFORM', date: new Date() },
          { ...user, fantasy_name: user.fantasy_name },
        ),
      ]);
    } catch (error) {
      return handleLoginError(error, !!isToken);
    } finally {
      setLoading(false);
    }
  }

  function handleLoginError(error, isToken) {
    const errors = error?.response;
    if (isToken) {
      if (errors?.data?.error_message === 'Invalid token') {
        addNotification(
          'Token inválido, insira o Token correto.',
          2,
          'Confirmação de acesso!',
          true,
        );
        return { token: true };
      }
    } else {
      if (errors?.data?.error_message === 'Invalid token') {
        addNotification(
          'Por favor, faça login novamente, insira o Token de acesso.',
          1,
          'Confirmação de acesso!',
          true,
        );
        return { token: true };
      }
    }

    if (errors) {
      const { data, status } = errors;
      const { error_message } = data;
      if (status === undefined) {
        setError({ login: 'Error não mapeado' });
      } else if (status === 500) {
        setError({ login: 'Error servidor' });
      } else if (status === 400) {
        setError({
          login:
            error_message === 'User not found or password did not match'
              ? 'Email ou senha estão incorretos.'
              : error_message,
        });
      }
    }
    return { token: false };
  }
  async function userResetPassword(data) {
    await apiPost(data);
  }

  async function fetchCustomersListData() {
    try {
      setLoading({ customers: true, add: false });

      setCustomerListData([]);

      const { data } = await apiGet('/customer/list');

      const isCustomers = data.reverse().map((item) => {
        return formatCustomers(item);
      });

      setCustomerListData(isCustomers);
    } catch (error) {
      const err = await error.response;
      if (err?.status === 401) {
        setError({ login: 'Você não está autenticado' });
        userLogout();
      }
      if (err?.status === 403) {
        setError({ login: 'Usuário sem autorização' });
        setCustomerListData([]);
      }
      if (err?.status === 500) {
        setError({ login: 'Error servidor' });
      }
    } finally {
      setLoading({ customers: false, add: false });
    }
  }
  async function userLogout() {
    queryClient.clear();
    setError(false);
    setUser(null);
    setLoading(false);
    setOrderKey({ key: false, status: false });
    setOrderTransfer(false);
    setOrder(false);
    setListOrder([]);
    setAllBalance([]);
    setUser(null);
    setLogin(false);
    removeCookieUser();
    removeToken();
    setCustomers(false);
    removeCookieCompany();
    removeCookieCompanyId();
    removeCookieCompanies();
    setCompanies(false);
    setAllBalance(false);
    setStartExtract(0);
    setStartExtract(false);
    setWidgetConsignable(false);
    setWidgetTransfer(false);
    setSearchDocument(false);
    setExpend(true);
    setLimits(false);
    clearStatePayment();
    companyStorage.clearValue('companies');
    companyStorage.clearValue('companiesValues');
    clearValue('user');
    history.push('/');
  }

  return {
    user,
    userLogin,
    login,
    userLogout,
    loading,
    setLoading,
    error,
    userResetPassword,
    setError,
    setUser,
    setLogin,
    message,
    setMessage,
    //balance
    setBalance,
    balance,
    fetchGetBalance,
    //menu
    expend,
    setExpend,
    dropMenu,
    setDropMenu,

    //balance companies
    allBalance,
    //TokenValidate,
    companies,
    setCompanies,
    autoLogin,

    listConsignable,
    fetchGetListConsignable,
    fetchGetAllBalances,
    isLinkClicked,
    setIsLinkClicked,
    fetchCustomersListData,
    fetchGetListLinked,
    fetchCollaborator,
    customerListData,
  };
}
